import { Observable, Subscription, take } from 'rxjs'
import { Workspace } from '@awork/features/workspace/models/workspace.model'
import { ApiClient } from '@awork/_shared/services/api-client/ApiClient'
import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit, Input } from '@angular/core'
import { Validators, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms'
import { showFormErrors } from '@awork/_shared/functions/show-form-errors'
import { hideDelay } from '../../../_shared/functions/hide-delay'
import { SelectOption } from '@awork/_shared/models/select-option.model'
import { apiEndpoint, version, releaseName, buildName } from '@awork/environments/environment'
import { User } from '@awork/features/user/models/user.model'
import { WithGlobals } from '../../../_shared/classes/with-globals'
import { AppQuery } from '@awork/core/state/app.query'
import { UserQuery } from '@awork/features/user/state/user.query'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { AutoUnsubscribe } from '@awork/_shared/decorators/auto-unsubscribe'
import { NgFor } from '@angular/common'
import { ModalComponent } from '../../../_shared/components/layout/modal/modal.component'
import { TextFieldComponent } from '../../../_shared/components/inputs/text-field/text-field.component'
import { DropdownFieldComponent } from '../../../_shared/components/inputs/dropdown-field/dropdown-field.component'
import { MultilineTextFieldComponent } from '../../../_shared/components/inputs/multiline-text-field/multiline-text-field.component'
import { ButtonComponent } from '../../../_shared/components/buttons/button/button.component'
import { FileFieldComponent } from '../../../_shared/components/inputs/file-field/file-field.component'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { HttpHeaders } from '@angular/common/http'
import { DynamicRefService } from '../../../_shared/services/dynamic-ref-service/dynamic-ref.service'

interface UploadResponse {
  fileName: string
  publicUrl: string
  validUpTo: Date
}

@AutoUnsubscribe()
@Component({
  selector: 'aw-ticket-creation-modal',
  templateUrl: './ticket-creation-modal.component.html',
  styleUrls: ['./ticket-creation-modal.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgFor,
    ModalComponent,
    TextFieldComponent,
    DropdownFieldComponent,
    MultilineTextFieldComponent,
    ButtonComponent,
    FileFieldComponent
  ]
})
export class TicketCreationModalComponent extends WithGlobals implements OnInit, AfterViewInit, OnDestroy {
  @Input() error: Error

  @Output() showing: EventEmitter<void> = new EventEmitter<void>()
  @Output() hiding: EventEmitter<void> = new EventEmitter<void>()
  @Output() saved: EventEmitter<void> = new EventEmitter<void>()

  @ViewChild('modal') modal: ModalComponent

  protected subscriptions: Subscription[] = []
  fileUploads: UploadResponse[] = []
  filesVisualization: FileUpload[] = []

  currentUser: User
  currentWorkspace: Workspace

  isSuccess = false
  isLoading = false
  isFileUploading = false
  errorFileCreated: boolean

  types: SelectOption[] = [
    { value: 'question', label: q.translations.common.feedback.question },
    { value: 'incident', label: q.translations.common.feedback.incident }
  ]

  ticketForm: FormGroup<{
    subject: FormControl<string>
    description: FormControl<string>
    type: FormControl<string>
  }>

  formErrors = {
    subject: '',
    description: ''
  }
  validationMessages = {
    subject: {
      required: q.translations.common.feedback.validation.subjectRequired
    },
    type: {
      required: q.translations.common.feedback.validation.typeRequired
    },
    description: {
      required: q.translations.common.feedback.validation.descriptionRequired
    }
  }

  constructor(
    private userQuery: UserQuery,
    private workspaceQuery: WorkspaceQuery,
    private apiClient: ApiClient,
    private appQuery: AppQuery
  ) {
    super()
  }

  ngOnInit() {
    this.createErrorFile()
    this.buildForm()

    this.subscriptions.push(
      this.userQuery.selectCurrentUser().subscribe(user => {
        this.currentUser = user
      }),
      this.workspaceQuery.selectCurrentWorkspace().subscribe(workspace => {
        this.currentWorkspace = workspace
      })
    )
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.show())
  }

  ngOnDestroy() {}

  /**
   * Show the modal
   */
  show(): void {
    if (this.modal && this.modal.show) {
      this.modal.show()
      this.showing.emit()
    }
  }

  /**
   * Hide the modal
   */
  hide(): void {
    this.modal.hide()
    setTimeout(() => {
      this.hiding.emit()
    }, 0)
  }

  /**
   * Creates the reactive form
   */
  private buildForm(): void {
    this.ticketForm = new FormGroup({
      subject: new FormControl(this.error && !this.errorFileCreated ? this.error.message : '', Validators.required),
      description: new FormControl(this.error && !this.errorFileCreated ? this.error.stack : '', Validators.required),
      type: new FormControl(this.error ? 'incident' : 'question', Validators.required)
    })
  }

  /**
   * Creates a text file with the error information and attaches it
   * @private
   */
  private createErrorFile(): void {
    if (!this.error) {
      return
    }

    try {
      const file = new File([`${this.error.message}\r\n\r\n${this.error.stack}`], 'error-info.txt', {
        type: 'text/plain'
      })

      this.fileChangeListener(null, file)

      this.errorFileCreated = true
    } catch (_) {}
  }

  /**
   * Handles the file selection to send it to the API
   * @param event
   * @param createdFile
   */
  fileChangeListener(event: Event, createdFile?: File): void {
    let file: File

    if (createdFile) {
      file = createdFile
    } else if (event) {
      const fileInput = event.target as HTMLInputElement
      if (fileInput.files.length === 0) {
        return
      }
      file = fileInput.files[0]
    }

    this.isFileUploading = true
    this.apiClient
      .post<UploadResponse>(`${apiEndpoint}/integrations/tickets/upload`, this.getFormData(file), {
        reportProgress: false,
        headers: new HttpHeaders().set('ngsw-bypass', 'true')
      })
      .subscribe(uploadResponse => {
        this.fileUploads.push(uploadResponse)
        this.filesVisualization = [
          ...this.filesVisualization,
          new FileUpload({ id: uploadResponse.fileName, name: uploadResponse.fileName })
        ]
        this.isFileUploading = false
      })
  }

  /**
   * Get form data for the attachment/file
   * @param {File} attachment
   * @returns {FormData} formData
   * @private
   */
  private getFormData(attachment: File): FormData {
    const formData: FormData = new FormData()
    formData.append('file', attachment, attachment.name)
    formData.append('name', attachment.name)
    formData.append('filename', attachment.name)
    return formData
  }

  /**
   * Send the new task list to the API
   */
  sendTicket(): void {
    if (!showFormErrors(this.ticketForm, this.formErrors, this.validationMessages)) {
      this.isLoading = true

      this.appQuery
        .selectTraces()
        .pipe(take(1))
        .subscribe(traces => {
          const traceIds = traces.traceIds.map(trace => `success:${trace}`)
          const traceIdsError = traces.traceIdsError.map(trace => `error:${trace}`)

          const ticketObject = {
            subject: this.ticketForm.get('subject').value,
            description: this.ticketForm.get('description').value,
            ticketType: this.ticketForm.get('type').value == 'question' ? 0 : 1,
            files: this.fileUploads.map(file => ({ name: file.fileName, url: file.publicUrl })),
            appVersion: navigator.appVersion,
            release: `${releaseName}: ${version} (${buildName})`,
            traceIds: traceIds,
            errors: traceIdsError,
            userLocation: window.location.href,
            channel: 'web',
            workspaceUrl: this.currentWorkspace.workspaceUrl
          }

          // Send the ticket to the API
          const url = `${apiEndpoint}/integrations/tickets`
          this.subscriptions.push(
            this.apiClient.post<void>(url, ticketObject).subscribe(
              response => {
                this.onSuccess()
              },
              error => {
                this.isLoading = false
              }
            )
          )
        })
    }
  }

  /**
   * When the ticket has been created successfully, reset the form and close the modal
   */
  onSuccess(): void {
    this.isLoading = false
    this.isSuccess = true
    this.saved.emit()

    hideDelay(() => {
      this.hide()
    })
  }

  /**
   * Renders the modal component
   * @param {Error} error
   * @returns {Observable<void>}
   */
  static renderModal(error?: Error): Observable<void> {
    const [modalRef, modal] = DynamicRefService.instance.create(this)

    modal.error = error

    modal.hiding.subscribe(() => modalRef.destroy())

    return modal.saved
  }
}
